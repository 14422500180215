import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type ISettingsParams = {
  time: SettingsParamType.Text
  location: SettingsParamType.Text
  RSVPTitle: SettingsParamType.Text
  registrationClosedButtonText: SettingsParamType.Text
  oneButton: SettingsParamType.Text
  listButtonText: SettingsParamType.Text
  todayButtonText: SettingsParamType.Text
  listGeneralTitle: SettingsParamType.Text
  soldOutRibbonText: SettingsParamType.Text
  joinWaitlistRibbonText: SettingsParamType.Text
  rsvpClosedRibbonText: SettingsParamType.Text
  membershipRibbonText: SettingsParamType.Text
  recurringRibbonText: SettingsParamType.Text
}

const settingsParams = createSettingsParams<ISettingsParams>({
  time: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  location: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  RSVPTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  registrationClosedButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  oneButton: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  listButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  todayButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  listGeneralTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  soldOutRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  joinWaitlistRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  rsvpClosedRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  membershipRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  recurringRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
})

export default settingsParams
