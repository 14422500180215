import React from 'react'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {isWUTImageEnabled} from '../../../../../commons/selectors/experiments'
import {getComponentConfig, isFitImage, isSingleEventWidget} from '../../selectors/settings'
import {isMobile} from '../../../../../commons/selectors/environment'
import {EventImage as OldPresentation} from './event-image-old'
import {EventImage as NewPresentation} from './event-image'
import {EventImageProps, EventImageStateProps, EventImageOwnProps} from './interfaces'

interface ExperimentProps {
  newImage: boolean
}

const Presentation = ({newImage, ...rest}: EventImageProps & ExperimentProps) =>
  newImage ? <NewPresentation {...rest} /> : <OldPresentation {...rest} />

const mapState = ({state}: AppProps) => ({
  newImage: isWUTImageEnabled(state),
  fitImage: isFitImage(getComponentConfig(state), isMobile(state)),
  singleLayout: isSingleEventWidget(getComponentConfig(state)),
})

export const EventImage = connect<EventImageOwnProps, EventImageStateProps & ExperimentProps>(mapState)(Presentation)
