import {ExperimentsBag} from '@wix/wix-experiments'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const isNewCheckoutDropdownsEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, 'specs.events.ui.NewCheckoutDropdowns')

export const isWUTImageEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, 'specs.events.ui.WUTImage')
